import service from './services'
/* eslint-disable */
const api = {
    login(params) {
        return service.post('/user/login', params);
    },
    register(params) {
        return service.post('/saas/api/web/user', {...params, createWhere: 5, });
    },
    advisory(params) {
        return service.post('/operation/api/advisory', params);
    },
    addbookCodeing(params) {
        return service.post('/operation/api/addbookCodeing', params);
    },
    listOsmmAlgCase(params) {
        return service.post('/saas/api/listOsmmAlgCase', params, { headers: { 'authorization': window.localStorage.getItem('authorization'), 'refresh_token': window.localStorage.getItem('refresh_token') } });
    },
    addOsmmAlgResult(params) {
        return service.post('/saas/api/addOsmmAlgResult', params, { headers: { 'authorization': window.localStorage.getItem('authorization'), 'refresh_token': window.localStorage.getItem('refresh_token') } });
    },
    deleteOsmmAlgResultById(params) {
        return service.delete(`/saas/api/deleteOsmmAlgResultById/${params}`, {}, { headers: { 'authorization': window.localStorage.getItem('authorization'), 'refresh_token': window.localStorage.getItem('refresh_token') } });
    },
    listOsmmAlgResult(params) {
        return service.post('/saas/api/listOsmmAlgResult', params, { headers: { 'authorization': window.localStorage.getItem('authorization'), 'refresh_token': window.localStorage.getItem('refresh_token') } });
    },
    getUrl(params) {
        return service.post('/saas/api/file/getUrl', params, { headers: { 'authorization': window.localStorage.getItem('authorization'), 'refresh_token': window.localStorage.getItem('refresh_token') } });
    },
    upload(params) {
        return service.post('/saas/api/file/upload', params, { headers: { 'authorization': window.localStorage.getItem('authorization'), 'refresh_token': window.localStorage.getItem('refresh_token') } });
    },
    download(params) {
        return service.get(`/saas/api/file/download/${params}`, { headers: { 'authorization': window.localStorage.getItem('authorization'), 'refresh_token': window.localStorage.getItem('refresh_token') } });
    },
    getPassword(params) {
        return service.post('/operation/api/bookUserPwd', params);
    },
     // 资讯列表 
   newslist(params){
       return service.post('/operation/information/pageInfo',params, { headers: { 'authorization': window.localStorage.getItem('authorization'), 'refresh_token': window.localStorage.getItem('refresh_token') }})
    },
    // 资讯详情
    newsDetail(params){ 
       return service.get(`/operation/information/detail/${params}`,{ headers: { 'authorization': window.localStorage.getItem('authorization'), 'refresh_token': window.localStorage.getItem('refresh_token') }})
    },
    // 文件详情
    fileDetail(params){ 
        return service.get(`/fs/file/info/${params}`,{ headers: { 'authorization': window.localStorage.getItem('authorization'), 'refresh_token': window.localStorage.getItem('refresh_token') }})
    },

    // 激活paas
    activatePaas(params) {
        return service.get(`/operation/paas/activateAccount/user/${params}`);
    },
    // wxcode换取token
    getWeChatToken(params) {
        return service.get(`/user/weChatLogin/${params}/123`);
    },

}

export default api