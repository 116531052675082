/* eslint-disable */
import Vue from 'vue'
import VueRouter from 'vue-router'
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)

const routes = [
    // 工业视觉
    {
        path: '/viewHome',
        name: 'viewHome',
        component: () =>
            import ('@/views/viewHome.vue')
    },
    {
        path: '/viewCases',
        name: 'viewCases',
        component: () =>
            import ('@/views/viewCases.vue')
    },
    // 算法体验
    {
        path: '/algo',
        name: 'algo',
        component: () =>
            import ('@/views/algo.vue')
    },
    // 主营业务/算力服务
    {
        path: '/mainbusines',
        name: 'mainbusines',
        component: () =>
            import('@/views/comservices.vue')
    },
    // AI周边产品
    {
        path: '/peripherals',
        name: 'peripherals',
        component: () =>
            import('@/views/peripherals.vue')
    },
    {
        path: '/',
        name: 'Home',
        component: () =>
            import ('@/views/Home.vue')
    },
    // 公司介绍
    {
        path: '/introduce',
        name: 'introduce',
        component: () =>
            import ('@/views/introduce.vue')
    },
    // 解决方案
    // {
    //     path: '/solution',
    //     name: 'solution',
    //     component: () =>
    //         import ('@/views/solution.vue')
    // },
    // 产品
    {
        path: '/product',
        name: 'product',
        component: () =>
            import ('@/views/product.vue')
    },
    // 教学硬件
    {
        path: '/eduHardware',
        name: 'eduHardware',
        component: () =>
            import ('@/views/eduHardware.vue')
    },
    // 教学用具
    {
        path: '/eduAids',
        name: 'eduAids',
        component: () =>
            import ('@/views/eduAids.vue')
    },
    // 教学课程
    {
        path: '/eduCourse',
        name: 'eduCourse',
        component: () =>
            import ('@/views/eduCourse.vue')
    },
       // 教学代码案例
       {
        path: '/eduCases',
        name: 'eduCases',
        component: () =>
            import ('@/views/eduCases.vue')
    },
       // 私有云
       {
        path: '/eduPrivateCloud',
        name: 'eduPrivateCloud',
        component: () =>
            import ('@/views/eduPrivateCloud.vue')
    },
    // 资讯
    {
        path: '/news',
        name: 'news',
        component: () =>
            import ('@/views/news.vue')
    },
    // 中科逆熵
    {
        path: '/extrotec',
        name: 'extrotec',
        component: () =>
            import ('@/views/extrotec.vue')
    },
     // 智能中心
     {
        path: '/aicenter',
        name: 'aicenter',
        component: () =>
            import ('@/views/aicenter.vue')
    },
    // 联系我们
    {
        path: '/new-aboutus',
        name: 'new-aboutus',
        component: () =>
            import ('@/views/new-aboutus.vue')
    },
    // 登陆注册
    {
        path: '/login',
        name: 'login',
        component: () =>
            import ('@/views/login.vue')
    },
    // 关于我们
    {
        path: '/aboutus',
        name: 'aboutus',
        component: () =>
            import('@/views/aboutus.vue')
    },    
    // 咨询
    {
        path: '/consult',
        name: 'consult',
        component: () =>
            import ('@/views/consult.vue')
    },
    // 新闻详情
    {
        path: '/newsDetails',
        name: 'newsDetails',
        component: () =>
            import ('@/views/newsDetails.vue')
    },
    // 教育产品
    {
        path: '/education',
        name: 'education',
        component: () =>
            import ('@/views/eduCases.vue')
    },
    // 产品详情
    {
        path: '/productDetail',
        name: 'productDetail',
        component: () =>
            import ('@/views/productDetail.vue')
    },
    // 工业视觉检测
    {
        path: '/testing',
        name: 'testing',
        component: () =>
            import ('@/views/testing.vue')
    },
    // 交通解决方案
    {
        path: '/transport',
        name: 'transport',
        component: () =>
            import ('@/views/transport.vue')
    },

    // 通用解决方案
    {
        path: '/currency',
        name: 'currency',
        component: () =>
            import ('@/views/currency.vue')
    },
    // 通用解决方案子方案
    {
        path: '/currencyChild',
        name: 'currencyChild',
        component: () =>
            import ('@/views/currencyChild.vue')
    },
    // 购书
    {
        path: '/book',
        name: 'book',
        component: () =>
            import ('@/views/book.vue')
    },
    // 激活paas
    {
        path: '/activation',
        name: 'activation',
        component: () =>
            import ('@/views/activation.vue')
    },
    // 外部登录中转
    {
        path: '/loginCheck',
        name: 'loginCheck',
        component: () =>
            import ('@/views/loginCheck.vue')
    },
    // ai工业视觉
    {
        path: '/industrialVision',
        name: 'industrialVision',
        component: () =>
            import ('@/views/industrialVision.vue')
    },
    {
        path: '/aiTrain',
        name: 'aiTrain',
        component: () =>
            import ('@/views/aiTrain.vue')
    },
    // 行业案例
    {
        path: '/industryCases',
        name: 'industryCases',
        component: () =>
            import ('@/views/IndustryCases.vue')
    },
    // ai教育案例
    {
        path: '/aiCase',
        name: 'aiCase',
        component: () =>
            import ('@/views/aiCase.vue')
    },
]

const router = new VueRouter({
    mode: 'history',
    routes
})

export default router
