<template>
  <div class="router-solution">
    <div class="router-solution-left">
      <div
        class="solution-item"
        :class="{ isActive: clickIndex == index }"
        v-for="(item, index) in solutionList"
        :key="index"
        @click="choose(item, index)"
      >
        <span>{{ item.label }}</span>
        <div
          :style="{ backgroundImage: 'url(' + right + ')' }"
          class="item-icon background"
        ></div>
      </div>
    </div>
    <div class="router-solution-right">
      <div class="solution-title">
        {{ chooseItem.label }}
        <i
          class="el-icon-close"
          style="float:right"
          @click="$emit('close', false)"
        ></i>
      </div>
      <div class="solutionFlex">
        <div class="solutionFlex-left">
          <span
            v-for="item in chooseItem.solution"
            class="noneAcitveLabel"
            @click="toRoute(item)"
            :key="item.index"
            :class="{ activeLabel: item.isActive }"
          >
            {{ item.label }}
          </span>
        </div>
        <div class="solutionFlex-right">
          <div class="solutionFlex-right-label">热门推荐</div>
          <div
            class="solutionFlex-right-item"
            v-for="item in hotList"
            :key="item.index"
          >
            <div
              class="solutionFlex-right-item-img background"
              :style="{ backgroundImage: `url(${item.src})` }"
            ></div>
            <div class="solutionFlex-right-item-label">
              {{ item.label }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */

export default {
  data() {
    return {
      chooseItem: {},
      clickIndex: 0,
      right: require("@/assets/images/right-white.png"),
      solutionList: [
        {
          label: "通用解决方案",
          id: 1,
          isActive: true,
          solution: [
            {
              label: "智慧云平台",
              isActive: false,
              src: require("@/assets/images/solution/zs.png"),
              pages: {
                name: "智算云平台",
                photoList: [
                  {
                    name: "智算云平台",
                    src: require("@/assets/images/solution/1.jpg"),
                    content:
                      "南京智能计算中心由南京市麒麟科技创新园携手浪潮、寒武纪共同打造，采用领先的寒武纪思元270和思元290智能芯片及加速卡。通过算力的生产、聚合、调度和释放四大关键作业环节，提供人工智能应用所需的强大算力服务。已投入运营的AI算力规模，1小时可完成100亿张图像识别、300万小时语音翻译或1万公里的自动驾驶AI数据处理任务。",
                  },
                ],
                caseList: [
                  {
                    name: "工业视觉—智能矿石检测",
                    src: require("@/assets/images/solution/case.png"),
                    content:
                      "传统的生产流水线上产品下线后大多有一个目检环节，即通过人眼检查发现产品表面的缺陷和瑕疵，人工目检效率低、容易漏检，并且人工成本也越来越高，智能云边一体开放平台通过视觉分析算法模型极大提高了检测效率。",
                  },
                ],
                advantage: {
                  left:
                    "完全由南京麒麟管委会投资建设，全系列使用浪潮智能计算优化服务器及国产AI 计算卡，具有大规模、自主可控的优势。是“新基建”的重要组成部分，也是为全社会提供算力的重要公共服务设施。",
                  right:
                    "机房已通过等保三级认证，能够在统一安全策略下防护系统免受来自外部有组织的团体、拥有较为丰富资源的威胁源发起的恶意攻击、较为严重的自然灾难、以及其他相当危害程度的威胁所造成的主要资源损害，能够发现安全漏洞和安全事件，在系统遭到损害后，能够较快恢复绝大部分功能。",
                },
              },
            },
            {
              label: "智慧云存储",
              isActive: false,
              src: require("@/assets/images/solution/2.png"),
              pages: {
                name: "智慧云存储",
                photoList: [
                  {
                    name: "智慧云存储",
                    src: require("@/assets/images/solution/1.jpg"),
                    content:
                      "智算云存储为数据分析平台提供主要数据存储和数据管理服务。采用浪潮并行文件存储系统。在保证数据高可靠性和高可用性的同时，提供在线的横向扩展，数据可以动态的在存储集群中的各个节点中平衡，保证数据容量和IO负载的均衡。保证数据可存储、管理、可分析。100PB的扩展能力，满足未来5-10年的存储需求",
                  },
                ],
                caseList: [
                  {
                    name: "工业视觉—智能矿石检测",
                    src: require("@/assets/images/solution/case.png"),
                    content:
                      "传统的生产流水线上产品下线后大多有一个目检环节，即通过人眼检查发现产品表面的缺陷和瑕疵，人工目检效率低、容易漏检，并且人工成本也越来越高，智能云边一体开放平台通过视觉分析算法模型极大提高了检测效率。",
                  },
                ],
                advantage: {
                  left:
                    "完全由南京麒麟管委会投资建设，全系列使用浪潮智能计算优化服务器及国产AI 计算卡，具有大规模、自主可控的优势。是“新基建”的重要组成部分，也是为全社会提供算力的重要公共服务设施。",
                  right:
                    "机房已通过等保三级认证，能够在统一安全策略下防护系统免受来自外部有组织的团体、拥有较为丰富资源的威胁源发起的恶意攻击、较为严重的自然灾难、以及其他相当危害程度的威胁所造成的主要资源损害，能够发现安全漏洞和安全事件，在系统遭到损害后，能够较快恢复绝大部分功能。",
                },
              },
            },
            {
              label: "数据清洗与标注",
              isActive: false,
              src: require("@/assets/images/solution/2.png"),
              pages: {
                name: "数据清洗与标注",
                photoList: [
                  {
                    name: "数据清洗与标注",
                    src: require("@/assets/images/industry2.png"),
                    content:
                      "智算云存储为数据分析平台提供主要数据存储和数据管理服务。采用浪潮并行文件存储系统。在保证数据高可靠性和高可用性的同时，提供在线的横向扩展，数据可以动态的在存储集群中的各个节点中平衡，保证数据容量和IO负载的均衡。保证数据可存储、管理、可分析。100PB的扩展能力，满足未来5-10年的存储需求",
                  },
                ],
                caseList: [
                  {
                    name: "工业视觉—智能矿石检测",
                    src: require("@/assets/images/solution/case.png"),
                    content:
                      "传统的生产流水线上产品下线后大多有一个目检环节，即通过人眼检查发现产品表面的缺陷和瑕疵，人工目检效率低、容易漏检，并且人工成本也越来越高，智能云边一体开放平台通过视觉分析算法模型极大提高了检测效率。",
                  },
                ],
                advantage: {
                  left:
                    "完全由南京麒麟管委会投资建设，全系列使用浪潮智能计算优化服务器及国产AI 计算卡，具有大规模、自主可控的优势。是“新基建”的重要组成部分，也是为全社会提供算力的重要公共服务设施。",
                  right:
                    "机房已通过等保三级认证，能够在统一安全策略下防护系统免受来自外部有组织的团体、拥有较为丰富资源的威胁源发起的恶意攻击、较为严重的自然灾难、以及其他相当危害程度的威胁所造成的主要资源损害，能够发现安全漏洞和安全事件，在系统遭到损害后，能够较快恢复绝大部分功能。",
                },
              },
            },
            // { label:'数据容灾备份',isActive:false, src:require('@/assets/images/solution/1.jpg')},
            // { label:'科学计算',isActive:false, src:require('@/assets/images/ai2.png')},
            // { label:'智慧园区',isActive:false, src:''},
          ],
        },
        {
          label: "行业解决方案",
          id: 2,
          isActive: false,
          solution: [
            {
              label: "智慧教育",
              isActive: false,
              src: require("@/assets/images/solution/zs.png"),
              route: "/solution",
            },
            {
              label: "智慧交通",
              isActive: false,
              src: require("@/assets/images/solution/2.png"),
              route: "/transport",
            },
            {
              label: "工业视觉检测",
              isActive: false,
              src: require("@/assets/images/solution/2.png"),
              route: "/testing",
            },
          ],
        },
        // {  label:'其他解决方案', id:3, isActive:false,
        //     solution:[
        //
        //     ],
        // },
      ],
    }
  },
  methods: {
    choose(item, index) {
      this.clickIndex = index
      this.chooseItem = item
      const choose = JSON.parse(JSON.stringify(this.chooseItem))
      if (choose.solution && choose.solution.length > 4) {
        this.hotList = choose.solution.splice(0, 4)
      } else {
        this.hotList = choose.solution
      }
    },
    toRoute(data) {
      if (data.route) {
        this.$router.push(data.route)
      } else {
        this.$router.push({
          path:'/currencyChild',
          query:{label:data.label}
          })
        localStorage.setItem('data',JSON.stringify(data))
        // this.$emit("getData", data)
      }
    },
  },
  created() {
    this.chooseItem = this.solutionList[0]
    const item = JSON.parse(JSON.stringify(this.chooseItem))
    this.hotList = item.solution.splice(0, 4)
  },
}
</script>
<style lang="scss" scoped>
.el-icon-close {
  font-size: 0.18rem;
  cursor: pointer;
}
.el-icon-close:hover {
  color: #0061e3;
}
.solutionFlex {
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: calc(100% - 0.22em);
  &-left {
    width: 60%;
    height: 90%;
    position: relative;
    top: 0.1rem;
  }
  &-right {
    width: 60%;
    height: 98%;
    &-label {
      font-size: 0.16rem;
      color: #333;
      width: 100%;
      height: 0.3rem;
      line-height: 0.3rem;
      position: relative;
      top: -0.2rem;
      text-align: center;
    }
    &-item {
      width: 45%;
      height: 45%;
      float: left;
      margin: 0 2% 1% 2%;
      cursor: pointer;
      &-img {
        width: 100%;
        height: 80%;
        border: 1px solid #dedede;
      }
      &-label {
        text-align: center;
        font-size: 0.14rem;
      }
    }
  }
}
.router-solution {
  width: 100%;
  height: 4.4rem;
  display: flex;
  justify-content: space-between;
  &-left {
    width: 20%;
    height: 100%;
    background: #ebf2fb;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 0.14rem;
  }
  &-right {
    width: 80%;
    height: calc(100% - 0.4rem);
    border-left: 1px solid #dedede;
    background: #fff;
    padding: 0.2rem;
  }
}
.solution-item {
  display: flex;
  justify-content: space-between;
  height: 0.3rem;
  line-height: 0.3rem;
  cursor: pointer;
  padding: 0.1rem 0.3rem;
  position: relative;
  top: -20%;
  .item-icon {
    width: 0.3rem;
    height: 0.2rem;
    margin-top: 0.05rem;
  }
}
.solution-item:hover {
  background: #0061e3;
  color: #fff;
}
.isActive {
  background: #0061e3;
  color: #fff;
}
.solution-title {
  text-align: left;
  font-size: 0.22rem;
  color: #333;
  margin-bottom: 0.1rem;
}
.noneAcitveLabel {
  display: block;
  height: 0.5rem;
  line-height: 0.5rem;
  color: #606266;
  cursor: pointer;
  width: 1rem;
  text-align: left;
}
.noneAcitveLabel:hover {
  border-bottom: 1px solid #0061e3;
  color: #0061e3;
}
.activeLabel {
  border-bottom: 1px solid #0061e3;
  color: #0061e3;
}
</style>
